import React from 'react';
import result from 'lodash/result';
import classnames from 'classnames';
import Wahanda from 'common/wahanda';
import ItemRowView from '../summary-view/item-row';
import ItemRowGroupView from '../summary-view/item-group-row';

function getCards(payments) {
  if (payments) {
    return payments.filter((payment) => {
      const code = result(payment, 'code');
      const cardType = result(payment, 'cardType');
      return code === 'CARD' && cardType;
    });
  }
  return undefined;
}

function getVouchers(payments) {
  if (payments) {
    return payments.filter((payment) => {
      const code = result(payment, 'code');
      const amount = result(payment, 'amount');
      return code === 'VCHR' && amount !== 0;
    });
  }
  return undefined;
}

interface IPaymentMethodsProps extends React.HTMLAttributes<Element> {
  salesSummary: {};
}

export class PaymentMethods extends React.Component<IPaymentMethodsProps, {}> {
  state = {
    showCardPayments: false,
    showCardRefunds: false,
    showVoucherPayments: false,
    showVoucherRefunds: false,
  };

  getRow(amount, title, key, additionalClasses, expandableState) {
    const onClick = () => {
      const currentStatus = this.state[expandableState];
      if (expandableState !== null) {
        this.setState({
          [expandableState]: !currentStatus,
        });
      }
    };
    return (
      <ItemRowView
        title={title}
        amount={amount}
        key={key}
        additionalClass={additionalClasses}
        expandable={expandableState !== null}
        onClick={onClick}
      />
    );
  }

  getPaymentDetailRows(payments, propKeys) {
    const { amount, title, key, classes } = propKeys;
    const rows = [];
    if (payments) {
      payments.forEach((payment) => {
        rows.push(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
          this.getRow(
            result(payment, amount),
            result(payment, title),
            result(payment, key),
            classes,
          ),
        );
      });
    }
    return rows;
  }

  getVoucherRows(payments) {
    return this.getPaymentDetailRows(payments, {
      amount: 'amount',
      title: 'voucherIssuer',
      key: 'voucherIssuer',
      classes: 'voucher-item',
    });
  }

  getCardRows(cardPayments) {
    return this.getPaymentDetailRows(cardPayments, {
      amount: 'amount',
      title: 'cardType',
      key: 'cardType',
      classes: 'card-item',
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getRows(totalAmount, refundAmount, title, key, additionalClasses) {
    return (
      <ItemRowGroupView
        title={title}
        totalAmount={totalAmount}
        refundAmount={refundAmount}
        key={key}
        // @ts-expect-error ts-migrate(2322) FIXME: Property 'additionalClass' does not exist on type ... Remove this comment to see the full error message
        additionalClass={additionalClasses}
      />
    );
  }

  /*
   *  TODO:
   *
   *  A peculiar construction ... What is a valid value for "amount"?
   */
  getOptionalRow(amount, title, key, additionalClasses, expandableState) {
    if (amount) {
      return this.getRow(amount, title, key, additionalClasses, expandableState);
    }
    return undefined;
  }

  render() {
    const salesSummary = this.props.salesSummary;
    const payments = result(salesSummary, 'paymentTotals');
    const refunds = result(salesSummary, 'refundTotals');
    const lang = Wahanda.lang.reports.transactions.daySummary.paymentMethods;
    const cardPayments = getCards(result(salesSummary, 'payments'));
    const cardRefunds = getCards(result(salesSummary, 'refunds'));
    const voucherPayments = getVouchers(result(salesSummary, 'payments'));
    const voucherRefunds = getVouchers(result(salesSummary, 'refunds'));
    const cardPaymentRows = this.state.showCardPayments ? this.getCardRows(cardPayments) : [];
    const cardRefundRows = this.state.showCardRefunds ? this.getCardRows(cardRefunds) : [];
    const voucherPaymentRows = this.state.showVoucherPayments
      ? this.getVoucherRows(voucherPayments)
      : null;
    const voucherRefundRows = this.state.showVoucherRefunds
      ? this.getVoucherRows(voucherRefunds)
      : null;
    return (
      <div className="sales-summary">
        <div className="item important">{lang.heading}</div>
        <div className="item-container">
          {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 4. */}
          {this.getRows(
            result(payments, 'cash', 0),
            result(refunds, 'cash', 0),
            lang.cashSales,
            'cashSales',
          )}
          <div className="item-group">
            {this.getRow(
              result(payments, 'card', 0),
              lang.cardSales,
              'cardSales',
              `expand${this.state.showCardPayments ? ' open' : ''}`,
              this.getCardRows(cardPayments).length > 0 ? 'showCardPayments' : null,
            )}
            {cardPaymentRows}
          </div>

          <div className="item-group">
            {this.getOptionalRow(
              result(refunds, 'card', 0),
              lang.cardRefunds,
              'cardRefunds',
              `expand${this.state.showCardRefunds ? ' open' : ''}`,
              this.getCardRows(cardRefunds).length > 0 ? 'showCardRefunds' : null,
            )}
            {cardRefundRows}
          </div>

          <div className="item-group">
            {this.getRow(
              result(payments, 'voucher', 0),
              lang.voucherSales,
              'voucherSales',
              classnames('expand', { open: this.state.showVoucherPayments }),
              this.getVoucherRows(voucherPayments).length > 0 ? 'showVoucherPayments' : null,
            )}
            {voucherPaymentRows}
          </div>

          <div className="item-group">
            {this.getOptionalRow(
              result(refunds, 'voucher', 0),
              lang.voucherAndOtherRefudns,
              'voucherRefunds',
              classnames('expand', { open: this.state.showVoucherRefunds }),
              this.getVoucherRows(voucherRefunds).length > 0 ? 'showVoucherRefunds' : null,
            )}
            {voucherRefundRows}
          </div>

          {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 4. */}
          {this.getRows(
            result(salesSummary, 'totalPrepaidAmount', 0),
            result(salesSummary, 'prepaidTotalRefundsAmount', 0),
            lang.marketplaceSales,
            'marketplaceSales',
          )}
        </div>
      </div>
    );
  }
}
